// now carrierLicensePlate field is readoly so this update form is not used
<template>
  <div>
    <v-col>
      <v-autocomplete
        v-model="carrierType"
        :items="carrierTypes"
        validate-on-blur
        :rules="required ? [rules.required] : []"
        label="Typ pojazdu"
        placeholder="Wybierz typ pojazdu"
        outlined
        dense
      />
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="carrierId"
        :items="carrierType === 'Vehicle' ? vehicles : trailers"
        validate-on-blur
        item-text="licensePlate"
        item-value="id"
        :rules="required ? [rules.required] : []"
        :disabled="!carrierType"
        label="Nr rejestracyjny pojazdu"
        placeholder="Wybierz nr rejestracyjny pojazdu"
        outlined
        dense
        @change="$emit('update', { carrierId, carrierType })"
      />
    </v-col>
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapActions, mapState } from 'vuex'
import { get } from 'lodash'

export default {
  mixins: [inputMixin],
  props: {
    required: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      carrierType: null,
      carrierId: null,
    }
  },
  computed: {
    ...mapState({
      trailers: state => state.trailers.items,
      vehicles: state => state.vehicles.items,
      carrierTypes: state => state.core.filters.carrierTypes,
    }),
  },
  methods: {
    ...mapActions({
      getTrailers: 'trailers/getItems',
      getVehicles: 'vehicles/getItems',
    })
  },
  mounted() {
    this.getVehicles()
    this.getTrailers()
    const carrierClass = this.data.carrier.class
    const trailerType = carrierClass.includes('Trailer') ? 'Trailer' : null
    const vehicleType = carrierClass.includes('Vehicle') ? 'Vehicle' : null
    this.carrierType = trailerType || vehicleType || null
    this.carrierId = get(this.data, 'carrier.id', '')
  }
}
</script>
